.multi-range-slider {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: relative;

	.slider {
		position: relative;
		width: 100%;
	}

	.slider-track,
	.slider-range,
	.slider-left-value,
	.slider-right-value {
		position: absolute;
	}

	.slider-track,
	.slider-range {
		border-radius: 3px;
		height: 5px;
	}

	.slider-track {
		border: 1px solid theme-color("dark");
		width: 100%;
		z-index: 1;
	}

	.slider-range {
		background-color: theme-color("dark");
		z-index: 2;
	}

	.slider-left-value,
	.slider-right-value {
		color: #000;
		font-size: 12px;
		margin-top: 20px;
	}

	.slider-left-value {
		left: 0;
	}

	.slider-right-value {
		right: -0;
	}

	.thumb,
	.thumb::-webkit-slider-thumb {
		-webkit-appearance: none;
		-webkit-tap-highlight-color: transparent;
	}

	.thumb {
		pointer-events: none;
		position: absolute;
		height: 0;
		width: 100%;
		outline: none;
	}

	.thumb-left {
		z-index: 3;
	}

	.thumb-right {
		z-index: 4;
	}

	.thumb::-webkit-slider-thumb {
		background-color: theme-color("dark");
		border: none;
		border-radius: 50%;
		box-shadow: 0 0 1px 1px #ced4da;
		cursor: pointer;
		height: 15px;
		width: 15px;
		margin-top: 4px;
		pointer-events: all;
		position: relative;
	}

	.thumb::-moz-range-thumb {
		background-color: theme-color("dark");
		border: none;
		border-radius: 50%;
		box-shadow: 0 0 1px 1px #ced4da;
		cursor: pointer;
		height: 15px;
		width: 15px;
		margin-top: 4px;
		pointer-events: all;
		position: relative;
	}
}