.panel {
	width: $width-panel;
	position: relative;
	background-color: #FFF;
	overflow-x: hidden;
	vertical-align: top;
	z-index: 400;
    -webkit-box-shadow: -10px 0px 14px -10px rgba(0,0,0,0.2);
    -moz-box-shadow: -10px 0px 14px -10px rgba(0,0,0,0.2);
    box-shadow: -10px 0px 14px -10px rgba(0,0,0,0.2);
    min-height: calc(100vh - #{$height-header});
	min-height: calc(100vh - #{$height-header});
	
	p {
		font-size: 12px;
		font-weight: 200;
	}

	.btn-link {
		font-weight: 200;
	}

	.buttons {
		flex-wrap: wrap;
		gap: 0.25rem;
	}

	.floor {
		img {
			max-width: 80%;
			height: 100%;
		}
	}
}

.active-filters + .panel {
	max-height: calc(100vh - #{$height-header} - #{$height-filters});
	min-height: calc(100vh - #{$height-header} - #{$height-filters});
}