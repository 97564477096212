table {
	text-align: center;
	color: #6E717D;

	thead {
		border-bottom: 1px solid #D2D3D4;
		font-weight: bold;
		font-size: 12px;
		line-height: 13px;
	}

	tbody {
		font-size: 12px;
		font-weight: 300;
	}

	th, td {
		padding: 5px 15px;
	}
}