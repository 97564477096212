.container-filters {
	position: absolute;
	left: -#{$width-panel};
	top: $height-header;
	width: $width-panel;
	height: calc(100vh - #{$height-header});
	z-index: 1001;
	transition: all 0.5s ease;

	$btn-toggle-size: 60px;
	.btn-toggle {
		z-index: 1000;
		position: absolute;
		border: none;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		width: $btn-toggle-size;
		height: $btn-toggle-size;
		top: 15px;
		right: -#{$btn-toggle-size};
		outline: 0;
		box-shadow: none;

		svg {
			margin: 0;
			font-size: 18px
		}
	}

	&.active {
		left: 0;
	}

	.filters {
		background-color: #FFF;
		transition: all 0.5s ease;
		overflow-y: auto;
		height: 100%;
	}
}