$color-primary: #4fa0a5;
$color-secondary: #e9604a;
$color-navbar: #E9E9E9;
$light-grey: #d9d9d9;
$dark-grey: #2A2D35;
$green: #6abe45;

$height-filters: 170px;
$height-header: 60px;
$width-panel: 400px;

$theme-colors: (
	"primary": $color-primary,
	"light": $light-grey,
	"dark": #1F2041
);

$input-btn-focus-width:       .2rem !default;
$input-btn-focus-color:       rgba(255, 255, 255, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;