.sc-card {
	position: absolute;
	z-index: 9999;
	left: 10px;
	top: 10px;
	width: calc(100% - 20px);
	height: calc(100% - 60px);
	transition: height 0.5s ease;

	.sc-container {
		text-align: center;
		position: relative;
		height: calc(100% - 34px);
	}
}

.active-filters .sc-card {
	height: calc(100% - 40px);
}

.sc-svg {
	position: relative;
	max-width: 100%;
	max-height: 100%;
	height: 100%;

	svg {
		height: 100%;
		max-width: 100%;
		max-height: 100%;

		g {
			cursor: pointer;
		}
	}
}

#svg-tooltip {
	position: absolute;
	display: none;
	z-index: 9999;
	padding: 6px;
	background-color: #fff;
	border-radius: 3px;
	color: #222;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
	opacity: 0.9;
	font-size: 12px;
	
	&::before {
		content: "";
		position: absolute;
		border: 6px solid transparent;
		background: transparent;
		left: 0;
		margin-left: -12px;
		border-right-color: #fff;
		top: 50%;
		margin-top: -6px;
	}
}