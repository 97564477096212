.wanquality-legend {
	position: absolute;
	bottom: 12px;
	left: 0;
	background-color: $color-navbar;
	width: calc(100% - 30px - 44px - 10px);
	z-index: 999;
	margin: 15px;
	padding: 5px 15px;
	display: flex;
	align-items: center;

	&-title {
		margin-bottom: 0;
		text-transform: uppercase;
		font-weight: bold;
	}

	&-block {
		display: flex;
		justify-content: center;
		margin: 5px 15px;

		&-color {
			display: inline-block;
			width: 20px;
			height: 20px;
			border-radius: 5px;
			margin-right: 10px;
		}

		p {
			margin-bottom: 0;
		}
	}
}