.custom-checkbox {
	.custom-control-input {
		&~.custom-control-label::before {
			color: white;
			border-radius: 0;
			border: none;
		}
	}

	.custom-control-input:checked {
		&~.custom-control-label::before {
			border: 1px solid #FFF;
		}
	}

	.custom-control-label {
		line-height: 23px;
	}
}

.custom-control-label::before {
	transition: none;
}

.checkbox-button {
	input {
		display: none;

		& + label {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 60px;
			height: 60px;
			padding: 10px;
			background-color: $color-primary;
			border-radius: 5px;
			opacity: 0.5;
			cursor: pointer;
			margin: 0;
			line-height: 18px;
			font-weight: 700;
			font-size: 16px;
			text-align: center;
		}

		&:checked + label {
			opacity: 1;
			border: 2px solid #FFF;
			position: relative;

			&::after {
				$size: 16px;
				content: '✓';
				font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
				color: #000;
				width: $size;
				height: $size;
				line-height: $size;
				text-align: center;
				position: absolute;
				bottom: -#{$size/2};
				background-color: #FFF;
				border-radius: 50%;
				font-weight: 500;
				font-size: 15px;
			}
		}
	}

	$colors-checkbox: $color-primary, #777777, #8ecbe3;
	@for $i from 1 through 3 {
		&:nth-child(#{$i}) input + label {
			background-color: nth($colors-checkbox, $i);
		}
	}
}